import React, { useState } from "react";
import ToggleSwitch from "./ToggleSwitch";

const MusicToggle = ({ onToggle }) => {
  const [musicState, setMusicState] = useState("low");

  const handleToggle = (newState) => {
    setMusicState(newState);
    onToggle(newState);
  };

  return (
    <div>
      <ToggleSwitch
        initialState={musicState === "low"}
        onToggle={handleToggle}
      />
    </div>
  );
};

export default MusicToggle;
