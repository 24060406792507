import React, { useRef, useState, useEffect } from "react";
import Background from "./RandomBackground";

const AudioVisualizer = ({ musicFolder }) => {
  const [audioURLs, setAudioURLs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const audioContextRef = useRef(new AudioContext());
  const analyserRef = useRef(audioContextRef.current.createAnalyser());
  const waveformRef = useRef(new Uint8Array(analyserRef.current.fftSize));
  const audioElementRef = useRef(null);
  const frameRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [userInteraction, setUserInteraction] = useState(false);

  useEffect(() => {
    const fetchAudioURLs = async () => {
      const getURLsFromFolder = (context) =>
        importAll(context).sort(() => Math.random() - 0.5);

      let urls;
      if (musicFolder === "low") {
        urls = getURLsFromFolder(
          require.context("./assets/audio/low", false, /\.(wav|mp3)$/)
        );
      } else if (musicFolder === "high") {
        urls = getURLsFromFolder(
          require.context("./assets/audio/high", false, /\.(wav|mp3)$/)
        );
      }

      setAudioURLs(urls);
      setCurrentIndex(0);
    };

    fetchAudioURLs();
  }, [musicFolder]);

  useEffect(() => {
    if (audioURLs.length > 0) {
      if (audioElementRef.current) {
        audioElementRef.current.removeEventListener("ended", playNextSong);
        audioElementRef.current.pause();
      }

      const audioElement = createAudioSource(audioURLs[currentIndex]);
      audioElement.addEventListener("ended", playNextSong);
      audioElement.addEventListener("timeupdate", handleTimeUpdate);
      audioElementRef.current = audioElement;
      setIsPlaying(false);
    }
  }, [audioURLs, currentIndex]);

  const importAll = (requireContext) => {
    return requireContext.keys().map(requireContext);
  };

  const createAudioSource = (audioURL) => {
    const audioElement = new Audio(audioURL);
    const audioSource =
      audioContextRef.current.createMediaElementSource(audioElement);
    audioSource.connect(analyserRef.current);
    analyserRef.current.connect(audioContextRef.current.destination);

    return audioElement;
  };

  const togglePlayback = () => {
    if (audioElementRef.current && audioElementRef.current.paused) {
      audioContextRef.current.resume().then(() => {
        audioElementRef.current.play();
        setIsPlaying(true);
      });
    } else if (audioElementRef.current && !audioElementRef.current.paused) {
      audioElementRef.current.pause();
      audioContextRef.current.suspend();
      setIsPlaying(false);
    }
  };

  const playNextSong = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex === audioURLs.length - 1 ? 0 : prevIndex + 1;

      if (audioElementRef.current) {
        audioElementRef.current.removeEventListener(
          "timeupdate",
          handleTimeUpdate
        );
        audioElementRef.current.removeEventListener("ended", playNextSong);
        audioElementRef.current.pause();
      }

      const nextAudioURL = audioURLs[nextIndex];
      const nextAudioElement = createAudioSource(nextAudioURL);
      nextAudioElement.addEventListener("ended", playNextSong);
      nextAudioElement.addEventListener("timeupdate", handleTimeUpdate);
      audioContextRef.current.resume().then(() => {
        nextAudioElement.play().then(() => {
          setIsPlaying(true);
          audioElementRef.current = nextAudioElement;
        });
      });

      return nextIndex;
    });
  };

  const handleTimeUpdate = () => {
    if (audioElementRef.current) {
      const currentTime = audioElementRef.current.currentTime;
      const duration = audioElementRef.current.duration;
      const remainingTime = duration - currentTime;
      console.log(remainingTime);
      if (remainingTime <= 5 && remainingTime >= 0) {
        playNextSong();
      }
    }
  };

  const updateImageScale = () => {
    analyserRef.current.getByteTimeDomainData(waveformRef.current);

    const maxAmplitude = Math.max(...waveformRef.current);
    const normalizedWaveform = Array.from(
      waveformRef.current,
      (value) => value / maxAmplitude
    );
    const averageAmplitude =
      normalizedWaveform.reduce((sum, value) => sum + value, 0) /
      normalizedWaveform.length;

    const minScale = 0.5;
    const maxScale = 1.25;
    const scaleFactor = minScale + (maxScale - minScale) * averageAmplitude;

    frameRef.current.style.transform = `scale(${scaleFactor})`;

    requestAnimationFrame(updateImageScale);
  };

  useEffect(() => {
    if (isPlaying && userInteraction) {
      updateImageScale();
    }
  }, [isPlaying, userInteraction]);

  return (
    <div className="waveform-container">
      <div className="waveform-object" ref={frameRef}>
        <div id="waveform-frame" className="waveform-frame"></div>
      </div>
      <div className="waveform-controls">
        <button
          className={isPlaying ? "pause" : "play"}
          id="play-button"
          onClick={() => {
            togglePlayback();
            setUserInteraction(true);
          }}
        >
          <span>{isPlaying ? "Pause" : "Play"}</span>
        </button>
      </div>
      <Background currentIndex={currentIndex} musicFolder={musicFolder} />
    </div>
  );
};

export default AudioVisualizer;
