import React, { useState, useEffect, useRef } from "react";

function importAll(r) {
  return r.keys().map(r);
}

function Background({ currentIndex, musicFolder }) {
  const [backgrounds, setBackgrounds] = useState([]);
  const [activeBackground, setActiveBackground] = useState("");
  const [nextBackground, setNextBackground] = useState("");
  const nextBackgroundRef = useRef(null);
  const activeBackgroundRef = useRef(null);

  useEffect(() => {
    const fetchBackgroundURLs = () => {
      const context =
        musicFolder === "low"
          ? require.context(
              "./assets/backgrounds/low",
              false,
              /\.(webp|png|jpe?g|svg)$/
            )
          : require.context(
              "./assets/backgrounds/high",
              false,
              /\.(webp|png|jpe?g|svg)$/
            );

      const urls = importAll(context);
      setBackgrounds(urls);
    };

    fetchBackgroundURLs();
  }, [musicFolder]);

  useEffect(() => {
    if (backgrounds.length > 0) {
      setActiveBackground(backgrounds[currentIndex]);
      setNextBackground(backgrounds[(currentIndex + 1) % backgrounds.length]);
    }
  }, [currentIndex, backgrounds]);

  useEffect(() => {
    if (activeBackgroundRef.current) {
      activeBackgroundRef.current.classList.add("fade-out");

      const timeout = setTimeout(() => {
        setActiveBackground(nextBackground);
        setNextBackground(backgrounds[(currentIndex + 1) % backgrounds.length]);
        activeBackgroundRef.current.classList.remove("fade-out");
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, backgrounds]);

  return (
    <div className="background-container">
      {activeBackground && (
        <div
          className={`background background-active ${
            activeBackground ? "fade-out" : ""
          }`}
          ref={activeBackgroundRef}
          style={{ backgroundImage: `url(${activeBackground})` }}
        />
      )}
      {nextBackground && (
        <div
          className="background background-next"
          ref={nextBackgroundRef}
          style={{ backgroundImage: `url(${nextBackground})` }}
        />
      )}
    </div>
  );
}

export default Background;
