import React from "react";
import AddFrame from "./AddFrame";
import MusicToggle from "./MusicToggle";

const Sidebar = React.memo(({ isOpen, toggle, onToggle }) => {
  return (
    <div className={isOpen ? "sidebar open" : "sidebar"}>
      <button className="toggle-button" onClick={toggle}>
        <span className="accessible-hide">Toggle Sidebar</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className="sidebar-nav">
        <MusicToggle onToggle={onToggle} />
        <AddFrame />
      </div>
    </div>
  );
});

export default Sidebar;
