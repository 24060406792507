import React, { useState } from "react";

const ToggleSwitch = ({ initialState, onToggle, newState }) => {
  const [isOn, setIsOn] = useState(initialState);

  const handleToggle = () => {
    const newState = !isOn;
    setIsOn(newState);
    onToggle(newState ? "low" : "high");
  };

  return (
    <div
      className={`toggle-switch ${isOn ? "low" : "high"}`}
      onClick={handleToggle}
    >
      <div className="toggle-track">
        <div className="toggle-thumb"></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
