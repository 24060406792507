import React, { useState } from "react";

import AudioPlayer from "./AudioPlayer";
import Sidebar from "./Sidebar";

import "./App.scss";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [musicFolder, setMusicFolder] = useState("low");

  const handleToggle = (newState) => {
    setMusicFolder(newState);
  };

  return (
    <div className="App">
      <Sidebar isOpen={isOpen} toggle={toggleSidebar} onToggle={handleToggle} />
      <div className="player-container">
        <AudioPlayer musicFolder={musicFolder} />
      </div>
    </div>
  );
}

export default App;
