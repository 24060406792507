import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import close from "./assets/images/ui/close.svg";
import drag from "./assets/images/ui/drag.svg";
import logo from "./logo.svg";

const defaultFrame = {
  id: "default",
  image: logo,
};

const PopupForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [topText, setTopText] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [frames, setFrames] = useState([]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleTopTextChange = (e) => {
    setTopText(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Image = reader.result;
        setImage(base64Image);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFrame = {
      id: uuidv4(),
      topText: topText,
      title: title,
      image: image,
    };

    setFrames((prevFrames) => [...prevFrames, newFrame]);

    // Reset the form fields
    setTopText("");
    setTitle("");
    setImage(null);

    // Close the pop-up
    setIsOpen(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    const newFrames = Array.from(frames);
    const [movedFrame] = newFrames.splice(source.index, 1);
    newFrames.splice(destination.index, 0, movedFrame);

    setFrames(newFrames);
  };

  const handleRemoveFrame = (id) => {
    setFrames((prevFrames) => {
      const updatedFrames = prevFrames.filter((frame) => frame.id !== id);
      if (updatedFrames.length === 0) {
        stopRotation();
        return [defaultFrame];
      } else if (updatedFrames.length === 1) {
        stopRotation();
      }
      return updatedFrames;
    });
  };

  const rotationIntervalRef = useRef(null);

  const stopRotation = () => {
    clearInterval(rotationIntervalRef.current);
  };

  const startRotation = (time) => {
    const waveformFrame = document.getElementById("waveform-frame");
    const childElements = waveformFrame.children;
    let currentIndex = 0;

    const rotateFrames = () => {
      // Reset rotation for all frames
      for (let i = 0; i < childElements.length; i++) {
        childElements[i].classList.remove("is-active");
      }

      // Set rotation for the current frame
      childElements[currentIndex].classList.add("is-active");

      // Increment the index or start over if at the last frame
      currentIndex = (currentIndex + 1) % childElements.length;
    };

    // Start the initial rotation
    rotateFrames();

    const intervalId = setInterval(rotateFrames, time);

    return intervalId;
  };

  useEffect(() => {
    const containerContents = frames.map((frame, index) => {
      const frameElement = document.createElement("div");

      if (frame.topText) {
        const topTextElement = document.createElement("p");
        topTextElement.textContent = frame.topText;
        frameElement.appendChild(topTextElement);
      }
      if (frame.title) {
        const titleElement = document.createElement("h1");
        titleElement.textContent = frame.title;
        frameElement.appendChild(titleElement);
      }

      if (frame.image) {
        const imageElement = document.createElement("img");
        imageElement.src = frame.image; // Assign the Base64 string directly
        frameElement.appendChild(imageElement);
      }

      frameElement.classList.add("frame-object");
      frameElement.setAttribute("data-order", index);

      if (index === 0) {
        frameElement.classList.add("is-active");
      }

      return frameElement.outerHTML;
    });

    const otherDiv = document.getElementById("waveform-frame");

    if (frames.length === 0) {
      const defaultFrameElement = document.createElement("div");
      defaultFrameElement.classList.add("frame-object");
      defaultFrameElement.setAttribute("data-order", "0");
      defaultFrameElement.classList.add("is-active");

      const defaultLogo = document.createElement("img");
      defaultLogo.setAttribute("src", logo);
      defaultFrameElement.appendChild(defaultLogo);
      otherDiv.innerHTML = defaultFrameElement.outerHTML;
    } else {
      otherDiv.innerHTML = containerContents.join("");
    }

    if (frames.length > 1) {
      rotationIntervalRef.current = startRotation(5000);
    } else {
      stopRotation();
    }
  }, [frames]);

  return (
    <div>
      <div className="frame-builder">
        <button className="add-frame-button" onClick={handleOpen}>
          <span>Add Frame</span>
        </button>
      </div>
      {isOpen && (
        <div className="frame-popup">
          <form onSubmit={handleSubmit}>
            <div className="frame-form-row">
              <label htmlFor="topText">Top text:</label>
              <input
                name="topText"
                type="text"
                value={topText}
                placeholder="Top text"
                onChange={handleTopTextChange}
              />
            </div>
            <div className="frame-form-row">
              <label htmlFor="title">Title:</label>
              <input
                name="title"
                type="text"
                value={title}
                placeholder="Title"
                onChange={handleTitleChange}
              />
            </div>
            <div className="frame-form-row">
              <label htmlFor="image">Upload Image:</label>
              <input
                name="image"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
            <button type="submit">Submit</button>
          </form>
          <button
            className="frame-popup-close"
            width="40"
            height="40"
            onClick={handleClose}
          >
            <img src={close} alt="close" />
            <span>Close Pop-up</span>
          </button>
        </div>
      )}
      <div id="container">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="frameOrder">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {frames.map((frame, index) => (
                  <Draggable
                    key={frame.id}
                    draggableId={frame.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="frame-preview"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        data-rbd-draggable-context-id="0" // Set the same context ID as the parent Droppable
                        data-rbd-draggable-id={frame.id}
                      >
                        <button
                          className="remove-button"
                          onClick={() => handleRemoveFrame(frame.id)}
                        >
                          <div className="remove-frame-button">
                            <img src={close} alt="remove frame" />
                          </div>
                        </button>
                        <div
                          className="drag-handle"
                          {...provided.dragHandleProps}
                        >
                          <img src={drag} alt="drag" />
                        </div>
                        <p>{frame.topText}</p>
                        <h1>{frame.title}</h1>
                        {frame.image && <img src={frame.image} alt="frame" />}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default PopupForm;
